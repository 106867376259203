import { tr } from 'pmt-modules/i18n'
/**
 * This corresponds to ThirdPartyTypeEnum from the API (it's the thirdparty type, not the thirdparty identifier)
 */
export const ThirdParty = {
  LEGACY_PMT: 'LEGACY_PMT', // not a real Third Party, so several specific behaviours
  DELIVEROO: 'DELIVEROO', // specificity : doesn't give the user infos
  ZDP_STANDARD: 'ZDP_STANDARD', // a thirdparty of this type follows the ZDP standard (the API we defined)
  UBER_EATS: 'UBER_EATS',
}

export function getThirdPartyLabel(thirdParty) {
  switch (thirdParty) {
    case ThirdParty.LEGACY_PMT:
      return tr('global.thirdparty.LEGACY_PMT')
    case ThirdParty.DELIVEROO:
      return tr('global.thirdparty.DELIVEROO')
    case ThirdParty.ZDP_STANDARD:
      return tr('global.thirdparty.ZDP_STANDARD') // aka LYVEAT as it's the only one using this standard
    case ThirdParty.UBER_EATS:
      return tr('global.thirdparty.UBER_EATS')
    default:
      return thirdParty
  }
}

export const UberEatsEnv = ['PREPROD', 'PROD_TEST', 'PROD']

export const DeliverooEnv = [
  'PAYMYTABLE_SANDBOX',
  'PAYMYTABLE_PROD',
  // 'SEQUOIASOFT_TEST', should not be used anymore, so not displaying it in BO V2
]
